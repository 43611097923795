import React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="fofmain">
      <div className="fof">
            <h1>Error 404</h1><br/>
            <Link to="/" className="button is-link">Go Back</Link>
      </div>
    </div> 
  </Layout>
)

export default NotFoundPage
